<template>
	<div class="page-manage">
		
		<menu-list @click="addModule"></menu-list>

		<browse-box ref="browseBox" :list="list" :isSetData="isAddData" @submit="submit" @moveUp="moveUp" @moveDown="moveDown"
		 @remove="remove">
		</browse-box>

		<div class="edit-box">
			<span class="btn" @click="openNewWindow">页面装修教程</span>
			<edit-box :list="list" @move="handleCanMove"></edit-box>
		</div>

		<div class="btn-box">
			<el-button plain size="mini" @click="cancel">取消</el-button>
			<el-button plain size="mini" @click="preview">预览</el-button>
			<el-button plain size="mini" @click="submit(0)">保存页面</el-button>
			<el-button type="primary" size="mini" @click="submit(1)">保存并发布</el-button>
		</div>

		<div class="fixed-menu" @click="showMenu=!showMenu">
			<i class="el-icon-tickets" style="font-size: 20px;color:#1890ff;"></i>
		</div>

		<transition name="scaleRight">
			<div class="dragMenu" v-if="showMenu">
				<div class="box">
					<p class="title">模块管理</p>
					<draggable class="list" :list="list" :options="{animation: 100, handle:'.item'}">
						<div class="item" @click="scrollToElement(item)" v-for="(item, index) in list" :key="item.uid" v-if="item.moduleType !== 1 && item.moduleType !== 28">
							{{item.tipName}}

							<span class="menu-delete" @click.stop="deleteMenu(index)" title="删除模块">
								<Icon type="trash-a" :size="20" color="#1890ff"></Icon>
							</span>
						</div>
					</draggable>
				</div>
			</div>
		</transition>


		<el-dialog title="提示" :visible.sync="dialogVisible" width="400px">
			<span style="line-height: 24px;">若您未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开？</span>
			<span slot="footer" class="dialog-footer">
				<el-button size="mini" @click="dialogVisible = false">关闭</el-button>
				<el-button size="mini" type="primary" @click="sureCancel">确定离开</el-button>
			</span>
		</el-dialog>

		<el-dialog title="预览" :visible.sync="weiCodeShow" width="500px" @closed="weiCodeCancel">
			<div v-loading="codeding">
				<img :src="imgUrl+codePicture" alt="" v-if="codePicture" id="codeId" style="width:300px;display: block;margin: 30px auto">
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import menuList from './components/menuList';
	import browseBox from './components/browseBox';
	import editBox from './components/editBox';
	import batchCods from "@/components/bathCodes"

	import draggable from 'vuedraggable';
	import config from '@/config/index';
	
	import {
		templateList
	} from './data.js';

	import {
		editSave,
		editInit
	} from '../../api/pageManage.js';
	import apiList from '@/api/other';
	
	import {qyWeixinContactMeListRequest} from '@/api/sv1.0.0.js'

	export default {
		name: 'page-manage',
		components: {
			menuList,
			browseBox,
			editBox,
			draggable,
			batchCods
		},
		data() {
			return {
				list: [],
				imgUrl: config.IMG_BASE,
				pageId: this.$route.query.id || 0,
				isAddData: false,
				showMenu: false,
				dialogVisible: false,
				indexList: [],
				weiCodeData: {},
				weiCodeShow: false,
				codeding: false,
				codePicture: '',
				isGroup: this.$route.query.isGroup === 'group'
			};
		},
		methods: {
			openNewWindow() {
				window.open('https://www.yuque.com/docs/share/50d9fb32-e73c-42da-a860-8a9bcfbf6e70?#')
			},
			async initData() {

				if (!this.pageId) {
					this.setNormal();
					return;
				}

				const params = {
					Id: this.pageId
				}

				const res = await editInit(params);
				
				try {
					var list = JSON.parse(res.Result.DecorateData);
					// console.log(list)
					this.initList(list);
					
				} catch (error) {
					// console.log(error)
					this.setNormal();
				}


			},
			initList(list){
				list.map(item => {
					item.uid = item.uid || this.guid();
				
					if (!item.tipName && item.moduleType != 1) {
						item = this.filterRecord(item);
						if (this.indexList[item.moduleType]) {
							this.indexList[item.moduleType].push(item.index);
						} else {
							item.tipName = item.name + 1;
							item.index = item.index;
							this.indexList[item.moduleType] = [];
							this.indexList[item.moduleType].push(1);
						}
					} else {
						this.indexList[item.moduleType] = this.indexList[item.moduleType] || [];
						this.indexList[item.moduleType].push(item.index);
					}
				
					//list跟保存时候 返回的数据格式不一样， 把格式改成保存时一样的格式
					if (item.moduleType == 17) {
						if (item.data.list && item.data.list.Results){
							item.data.list = item.data.list.Results;
						}else{
							item.data.list = []
						}
						
						if(!item.data.type){
							item.data.type = 1;
						}
						if(!item.data.style){
							item.data.style = 1;
						}
					}
					
					if (item.moduleType == 24){
						item.data.list = item.data.list.Results.map(item=>{
							item.ImgUrlComplete = this.imgUrl + item.ImgUrl;
							item.ProductName = item.Name;
							item.ExclusivePrice = item.Price;
							item.id = item.ActivityFullId + '' + item.ProductId;
							return item;
						});
					}
					
					//column是后加的字段， 需要再初始化的时候 把这个字段加上
					if (item.moduleType == 2) {
						if (!item.data.column) {
							item.data.column = '3';
						} else {
							item.data.column = item.data.column + '';
						}
						
						if (typeof(item.data.groupType) == 'undefined'){
							item.data.groupType = 1;
						}
						if (typeof(item.data.sortList) == 'undefined'){
							item.data.sortList = [];
						}
						
					}
					
					if (item.moduleType == 16){
						if (typeof(item.data.showScan) == 'undefined'){
							item.data.showScan = false;
						}
					}
				
					if (item.moduleType == 22){
						if (typeof(item.data.groupList) == 'undefined'){
							item.data.groupList = [];
						}
						if (typeof(item.data.showFollowGroup) == 'undefined'){
							item.data.showFollowGroup = 1;
						}
						if (typeof(item.data.type) == 'undefined'){
							item.data.type = 1;
						}
						if (typeof(item.data.style) == 'undefined'){
							item.data.style = 1;
						}
						
						if (item.data.type === 1){
							item.data.groupList = [];
						}
						
					}
					
					if (item.moduleType === 1){
						if (typeof(item.data.shareImage) == 'undefined'){
							item.data.shareImage = '';
						}
						if (typeof(item.data.shareTitle) == 'undefined'){
							item.data.shareTitle = '';
						}
						if (typeof(item.data.bgColor) == 'undefined'){
							item.data.bgColor = '#f5f5f5';
						}
						item.name = '页面设置';
						item.tipName = '页面设置';
					}
					
					//猜你喜欢
					if(item.moduleType == 6){
						if (typeof(item.data.count) == 'undefined'){
							item.data.count = 50;
						}
					}
					
					//单排图片 增加展示购买记录
					if(item.moduleType == 9){
						if (typeof(item.data.showRecord) == 'undefined'){
							item.data.showRecord = false;
						}
					}
					if(item.moduleType == 28){
						// console.log(item, '28')
						if (item.data.contactId){
							this.checkModuleData();
						}
					}
					
					return item;
				})
				
				this.list = list;
				
				if (!this.list.length) {
					this.setNormal();
				}
			},
			async checkModuleData(){
				try{
					const res = await qyWeixinContactMeListRequest({
						Skip: 0,
						Take: 999999
					})
					var array = res.Result.Results;
					
					var list = array.filter(item=>{
						return item.Id == this.data.contactId
					})
					if (!list.length){
						this.list.map(item=>{
							if (item.moduleType == 28){
								item.data.contactId = null
							}
							return item;
						})
					}
					
				}catch(e){
					//TODO handle the exception
				}
			},
			setNormal() {
				this.list.push({
					moduleType: 1,
					name: '页面设置',
					tipName: '页面设置',
					uid: this.guid(),
					data: {
						visible: true,
						editVisible: true,
						title: '自定义页面',
						isShowShopName: true,
						isShowAttentionWechat: true,
						shareImage: '',
						shareTitle: '',
						bgColor: '#f5f5f5'
					}
				})
			},
			addModule(record) {
				switch (record.moduleType) {
					case 2: //单排商品
						this.setProductData(record);
						break;
					case 3: //双排商品
						this.setProductData(record);
						break;
					case 4: //三排商品
						this.setProductData(record);
						break;
					case 5: //商品列表
						this.setProductData(record);
						break;
					case 6: //猜你喜欢
						this.setGuessData(record);
						break;
					case 7: //视频
						this.setVideoData(record);
						break;
					case 8: //轮播图
						this.setProductData(record);
						break;
					case 9: //单排图片
						this.setProductData(record);
						break;
					case 10: //双排图片
						this.setPictureData(record);
						break;
					case 11: //图文导航
						this.setPictureData(record);
						break;
					case 12: //热区
						this.setHotAreaData(record);
						break;
					case 13: //标签定向栏
						this.setPictureData(record);
						break;
					case 14: //标题
						this.setTitleData(record);
						break;
					case 15: //文本
						this.setTextData(record);
						break;
					case 16: //搜索
						this.setTextData(record);
						break;
					case 17: //限时抢购
						this.setFlashSaleData(record);
						break;
					case 18: //优惠券
						this.setCouponData(record);
						break;
					case 19: //拼团
						this.setPictureData(record);
						break;
					case 20: //积分商城
						this.setPointShopData(record);
						break;
					case 21: //公告
						this.setTextData(record);
						break;
					case 22: //直播
						this.setLiveData(record);
						break;
					case 23: //新品
						this.setNewProductData(record);
						break;
					case 24: //社群专享品
						this.setFlashSaleData(record);
						break;
					case 25: //社群绑定
						this.setBindGroupData(record);
						break;
					case 26: //多人拼团
						this.setSpellGroupData(record);
						break;
					case 27: //助力领取
						this.setFlashSaleData(record);
						break;
					case 28: //加好友
						this.setAddFriend(record);
						break;
					case 29: //视频号直播
						this.setChannelsLive(record);
						break;
					case 30: //公众号涨粉
						this.setAddPowder(record);
						break;
					case 31: //邀请分享员
						this.setInvitingSharer(record);
						break;
					case 32: //多多商品
						this.setDuoduoShop(record);
						break;
					case 33: //多多营销
						this.setDuoduoMarketing(record);
						break;
					case 34: //肉团团购
						this.setRoutuantuangou(record);
						break;
				}

				this.isAddData = !this.isAddData;

			},
			setRoutuantuangou(record){
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					record.index = num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					record.index = 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}
				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					index: record.index,
					uid: this.guid(),
					data:{
						visible: false,
						editVisible: false,
						hideSpace: false,

					}
				})
			},
			setDuoduoShop(record){
				if (this.indexList[record.moduleType]&&this.indexList[record.moduleType].length) {
					this.$message.error('已添加多多商品专区模块，请勿重复添加')
					return 
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					record.index = num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					record.index = 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}
				// console.log(record)
				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					index: record.index,
					uid: this.guid(),
					data:{
						visible: false,
						editVisible: false,
						hideSpace: false,

						labelList:[],
						showBoutique:false,
						title:'精选',
						screen:[]//0佣金比例 1拼团价
					}
				})
			},
			setDuoduoMarketing(record){
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					record.index = num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					record.index = 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}
				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					index: record.index,
					uid: this.guid(),
					data:{
						visible: false,
						editVisible: false,
						hideSpace: false,

						list:[]
					}
				})
			},
			setInvitingSharer(record){
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					record.index = num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					record.index = 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}
				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					index: record.index,
					uid: this.guid(),
					data:{
						visible: false,
						editVisible: false,
						hideSpace: false,
					}
				})
				// console.log(this.list)
			},
			setAddPowder(record){
				// console.log(record)
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					record.index = num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					record.index = 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}
				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					index: record.index,
					uid: this.guid(),
					data:{
						visible: false,
						editVisible: false,
						styleType:0,//0 1
						img:'',//自定义图片
						guideLanguage:'',//引导语
						qrCode:'',//二维码
						hideSpace: false,
					}
				})
			},
			setProductData(record) {

				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					record.index = num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					record.index = 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					index: record.index,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						list: [],
						shopClassifylist: [],
						classifylist: [],
						moduleType: 'shop', //模块类型： 'shop':商品  'classify':分组
						shopType: 'shop',
						styleType: 'tiled',
						column: "3",
						iconType: 'hide',
						iconName: '热卖',
						hideSpace: false,
						showRecord: false,
						sortList: [], //排序与筛选
						groupType: 1, //分组显示位置  1.顶部 2.左侧
					}
				})
			},
			setGuessData(record) {

				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						hideSpace: false,
						count: 50
					}
				})
			},
			setBindGroupData(record){
				
				var list = this.list.filter(item=>{
					return item.moduleType === 25;
				})
				
				if (list.length){
					this.$message('社群绑定模块仅可添加一个');
					return;
				}
				
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}
				
				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						hideSpace: false
					}
				})
			},

			setSpellGroupData(record){
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						title: '',
						hideSpace: false,
						activityData: {},
						activitys:[],
						list: [],
						type: 1,
					}
				})
			},
			setVideoData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						videoType: 1,
						videoUrl: '', //'/ShopDecorate/a8e46c17-e780-4f8c-99bd-be3d42625b55.mp4'
						customVideoUrl: '',
						coverImage: '',
						hideSpace: false
					}
				})
			},
			setPictureData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						list: [],
						style: 1, //样式1 样式2
						hideSpace: false
					}
				})
			},
			setHotAreaData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						list: [],
						mainUrl: '', ///ShopDecorate/5021f779-b3bd-47e2-a0be-17ba565cc991.jpg
						hideSpace: false
					}
				})
			},
			setTitleData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						url: '',
						title: '',
						style: 1, //居左1，居右2
						hideSpace: false
					}
				})
			},
			setTextData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						text: '',
						hideSpace: false,
						selectedData: {},
						showScan: false
					}
				})
			},
			setFlashSaleData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						title: '',
						hideSpace: false,
						activityData: {},
						activitys:[],
						list: [],
						type: 1, //1单场 2多场
						style:1,
					}
				})
			},
			setCouponData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						autoFull: 1, //是否自动添加  1是 2否
						couponCount: 1, //自动添加 添加个数
						list: [], //手动添加 添加的优惠券数据
						style: 1, //优惠券样式 1 2 3  不可描述
						colorStyle: 1, //优惠券颜色样式 1.红色 2.黄色 3.黑色
						layoutStyle: 1, //排版样式 1两列 2滑动
						hideSpace: false
					}
				})
			},
			setPointShopData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						list: [], //商品列表
						couponList: [], //优惠券列表
						moduleType: 1, //1.商品 2.优惠券
						hideSpace: false
					}
				})
			},
			setLiveData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						list: [], //直播列表
						groupList: [], //分组列表
						showFollowGroup: 1, //1显示 2不显示
						type: 1, //1直播间 2直播间分组
						style: 1, //1详细列表 21排2列
						hideSpace: false
					}
				})
			},

			setNewProductData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						list: [],
						count: 0,
						hideSpace: false
					}
				})
			},
			setAddFriend(record){
				var arr = this.list.filter(item=>{
					return item.moduleType == 28;
				})
	
				if (arr.length){
					this.$message.error('[加好友]模块，仅可添加一次')
					return;
				}
				
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}
				
				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						hideSpace: false,
						style: 1, //样式配置  1.系统默认样式  2.自定义上传入口图片
						imageUrl: '', //自定义入口图片路径
						entranceImageType: 1, //涨粉入口图片类型 1.默认 2.自定义
						entranceImageUrlNormal: '/image/20210924-ab4d4146-34ae-4e7d-9e20-7434d475a083.png', // 涨粉入口图片-默认
						entranceImageUrl: '/image/20210924-ab4d4146-34ae-4e7d-9e20-7434d475a083.png', // 涨粉入口图片
						describe: '添加好友领福利啦~', //引导描述
						buttonText: '立即添加', //按钮文案
						// data: {},
						contactId: null
					}
				})
			},
			setChannelsLive(record){
				
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}
				
				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						hideSpace: false,
						style: 1, //进入直播间样式配置  1.系统默认样式  2.自定义上传入口图片
						coverImage: '', //直播间封面
						customInLiveImage: '', //自定义进入直播间入口样式
						showSubscribe: false, //是否显示预约入口
						subscribeStyle: 1, //预约样式配置  1.系统默认样式  2.自定义上传入口图片
						subscribeDesc: '', //预约介绍
						customSubscribeImage: '', //自定义预约样式
					}
				})
			},

			filterRecord(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					record.index = num;
				} else {
					record.tipName = record.name + 1;
					record.index = 1;
				}
				return record;
			},

			moveUp(index) {

				if (index <= 1) return;

				this.list.splice(index - 1, 1, ...this.list.splice(index, 1, this.list[index - 1]))
			},
			moveDown(index) {

				if (index === this.list.length - 1) return;

				this.list.splice(index + 1, 1, ...this.list.splice(index, 1, this.list[index + 1]))

			},
			remove(index) {
				this.indexList[this.list[index].moduleType].splice(this.list[index].index-1,1)
				// console.log(this.indexList)
				this.list.splice(index, 1)
			},
			deleteMenu(index) {
				this.list.splice(index, 1)
			},
			scrollToElement(record) {
				let elem = document.querySelector('#' + record.uid);
				let box = document.querySelector('.scroll-box');

				if (elem) {
					box.scrollTop = elem.offsetTop - box.offsetTop
				}
			},
			async submit(type, callback) {
				
				var list = JSON.parse(JSON.stringify(this.list));
				var array = [];
				list.map(item => {
					if (item.moduleType == 17) {
						item.data.list = (item.data.list||[]).map(obj => {
							return {
								ProductId: obj.ProductId
							}
						})
						item.data.activitys = (item.data.activitys||[]).map(obj => {
							var o = {};
							o.ActivityFullId = obj.Id;
							o.list = [];
							(obj.list||[]).map(i=>{
								if (i && i.ProductId){
									o.list.push(i.ProductId)
								}
							})
							return o;
						})
					}
					if (item.moduleType == 24) {
						item.data.list = item.data.list.map(obj => {
							return {
								ProductId: obj.ProductId,
								ActivityFullId: obj.ActivityFullId
							}
						})
					}
					if (item.moduleType == 2) {
						item.data.list = item.data.list.map(obj => {
							return {
								Id: obj.Id
							}
						})
						item.data.classifylist = item.data.classifylist.map(obj => {
							obj.ProductList = obj.ProductList.map(i => {
								return {
									Id: i.Id
								}
							})
							return obj;
						})
						item.data.shopClassifylist = item.data.shopClassifylist.map(obj => {
							obj.ProductList = obj.ProductList.map(i => {
								return {
									Id: i.Id
								}
							})
							return obj;
						})

						if (item.data.moduleType == 'shop' && item.data.shopType == 'shop') {
							item.data.classifylist = [];
							item.data.shopClassifylist = [];
						} else if (item.data.moduleType == 'shop' && item.data.shopType == 'classify') {
							item.data.list = [];
							item.data.classifylist = [];
						} else {
							item.data.list = [];
							item.data.shopClassifylist = [];
						}
					}
					
					if(item.moduleType == 22){
						item.data.list.map(obj=>{
							obj.CoverImgUrl = obj.AnchorImgUrl;
							return obj;
						})
						var list = [];
						(item.data.groupList || []).map(item=>{
							if (item.Id || item.Id === 0){
								list.push(item.Id);
							}
						})
						item.data.groupList = list;
					}
					if (item.moduleType == 26) {
						item.data.list = item.data.list.map(obj => {
							return {
								ProductId: obj.ProductId,
								ActivityFullId: obj.ActivityFullId
							}
						})
					}
					if(item.moduleType==30){
						if(item.data.styleType==1){
							item.data.guideLanguage = ''
						}else{
							item.data.img = ''
						}
					}
					if(item.moduleType==32){
						item.data.title = item.data.title||'精选'
					}
					
					array.push(item)
				})
				console.log(array)


				const params = {
					Id: this.pageId,
					DecorateData: JSON.stringify(array),
					PageName: this.list.filter(item => {
						return item.moduleType == 1;
					})[0].data.title,
					IsRelease: type ? true : false,
					IsWeixinGroupPage: this.isGroup
				}

				const result = await editSave(params);

				if (result.IsSuccess) {
					if (!callback) {
						this.$message({
							showClose: true,
							message: type ? '发布成功！' : '保存成功！',
							type: 'success'
						});
					}


					this.pageId = result.Result.Id;

					callback && callback();

					// setTimeout(()=>{
					// 	this.$router.replace({
					// 		path: '/mallPage/mallPagemange'
					// 	})
					// }, 1000)

				} else {
					this.$message.error(result.Message);
				}

			},
			cancel() {
				// this.$confirm('若您未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开？')
				// 	.then(_ => {
				// 		this.$router.replace({
				// 			path: '/mallPage/mallPagemange'
				// 		})
				// 	})
				// 	.catch(_ => {});
				this.dialogVisible = true;

			},
			handleCanMove(record, index, uid) {
				this.$refs['browseBox'].hotMove(record, index, uid)
				console.log(record, index, uid)
			},
			sureCancel() {
				this.dialogVisible = false;
				this.$router.replace({
					path: '/mallPage/mallPagemange'
				})
			},
			preview() {

				this.submit(false, async () => {
					this.weiCodeShow = true;
					try {
						this.codeding = true
						let data = {
							Page: `pages/cusPage/cusPage`,
							Scene: `id=${this.pageId}&view=1`,
							IsLimited: false,
							ProgramType: 0,
						}
						let result = await apiList.mallGetQrCode(data)
						this.codePicture = JSON.parse(result.Result)[0]
					} catch (e) {
						console.log('e', e)
					} finally {
						this.codeding = false
					}
				})


			},
			weiCodeCancel(value) {
				this.weiCodeShow = false;
			},
			guid() {
				var a = function() {
					return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
				};
				return 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
			}
		},
		mounted() {
			if (this.$route.query.templateId){
				var list = templateList.filter(item=>{
					return item.id == this.$route.query.templateId
				})
				
				if (list.length){
					var list = list[0].data;
					this.initList(list)
				}
			}else{
				this.initData();
			}
			
		},
		watch: {}
	};
</script>

<style lang="less" scoped>
	.page-manage {
		display: flex;
		justify-content: space-between;
		background: #e5e5e5;
		overflow: auto;
		position: absolute;
		left: 0px;
		top: 0;
		width: 100%;
		height: 100%;

		.edit-box {
			display: flex;
			width: 480px;
			background: #fff;
			margin-left: 50px;
			position: relative;
			.btn {
				position: absolute;
				right: 60px;
				top: 40px;
				color: #409EFF;
				font-size: 14px;
				cursor: pointer;
				z-index: 99;
			}
		}

		.item-box {
			display: flex;
			height: 100%;
		}

		.fixed-menu {
			position: fixed;
			right: 20px;
			top: 125px;
			width: 30px;
			height: 30px;
			line-height: 37px;
			background: #fff;
			text-align: center;
			z-index: 1000;
			box-shadow: 0 0 9px 1px rgba(0, 0, 0, .1);
			cursor: pointer;
		}

		.dragMenu {
			width: 240px;
			margin-left: 584px;
			position: fixed;
			top: 160px;
			right: 60px;
			bottom: 60px;
			z-index: 1000;
			box-shadow: 0 0 9px 1px rgba(0, 0, 0, .1);

			.box {
				display: flex;
				flex-direction: column;
				height: 100%;
				background: #fff;
				padding: 15px 20px;

				.title {
					font-size: 16px;
					margin-bottom: 15px;
					flex: 0 0 auto;
				}

				.item {
					position: relative;
					height: 36px;
					line-height: 36px;
					padding-left: 20px;
					font-size: 14px;
					color: #666;
					background: #f0f0f0;
					margin-bottom: 5px;
					cursor: pointer;

					.menu-delete {
						position: absolute;
						right: 10px;
						top: 2px;
						cursor: pointer;
					}
				}

				.list {
					flex: 1 1 auto;
					overflow: auto;
					padding-right: 10px;
				}
			}
		}

		.btn-box {
			position: absolute;
			right: 10px;
			bottom: 15px;
		}
	}

	* {
		user-select: none;
	}

	// ::-webkit-scrollbar {
	// width: 5px;
	// }

	// ::-webkit-scrollbar-track {
	// background-color: none;
	// }

	// ::-webkit-scrollbar-thumb {
	// background-color: none;
	// }

	// ::-webkit-scrollbar-thumb:hover {
	// background-color: none;
	// }

	// ::-webkit-scrollbar-thumb:active {
	// background-color: none;
	// }
</style>
